import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
} from "@fortawesome/pro-regular-svg-icons";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { Paper } from "@mui/material";
import Carousel from "react-multi-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-multi-carousel/lib/styles.css";
import {
  AvailableFareLegModel,
  AvailableFareModel,
  AvailableFareResultModel,
  FlightData,
  StopsData,
  TravelTimeView,
  b2cSettingsText,
} from "WebApiClient";
import { BuildFaresFilter } from "../business/BuildFilter";
import Fares, { AvailableFaresFare } from "./AvailableFares.Fares";
import FastFilter from "./AvailableFares.FastFilter";
import FaresFilter from "./AvailableFares.Filter";
import { SortFunction } from "./Fare.sortFunction";
import { AgentApiURL } from "Constants";
import Logo from "Styles/images/airline_not_found.gif";
import { currencyFormatter } from "components/Shared/Helpers/CurrencyConverter";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import { State } from "rootExports/rootReducer";
import _ from "lodash";
import { FaresFilterState } from "../types/AvailableFaresTypes";
import { FareAppliesToFilter } from "components/Fare/FareFilter.Applicability";
import FareHeaderList from "./FareHeaderList";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import classNames from "classnames";
import { LocalizationConverter } from "components/Shared/Helpers/LocalizationConverter";
import { useTranslation } from "react-i18next";
interface AvailableFaresProps {
  OriginalFare: AvailableFareResultModel;
  data: AvailableFareResultModel;
  BookFare: (payload: SetBookingPayload) => void;
  OnDiscard: () => void;
  OnModify: () => void;
  IsActive: boolean;
  OnReset: any;
  IsModifySearch: any;
  OnSearchSubmitted: any;
  LastSubmitted: any;
  submitResult: any;
  GoToResults: any;
  setHeightHolder?: any;
  heightHolder?: any;
  setActiveTabHF?: any;
  activeTabHF?: any;
  setFetching?: any;
  setError?: any;
  setResponse?: any;
  setExtraAncCheck?: (newValue: boolean) => void;
  extraAncCheck?: boolean;
  activeTab?: string;
  language: string;
  fareSortStores: any;
  setFareSortStores: any;
}

var arrCollector: any = [];
var cssActiveChecker: any = [];
var repeatChecker: string[] = [];
export const AvailableFares: React.FC<AvailableFaresProps> = (props) => {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=On"
        ? "menuPosition=L\nshowFareHeader=On\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : "menuPosition=L\nshowFareHeader=On\nshowSmartCalender=None/n[]/nfalse/nfalse"
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  const b2cSettingsText = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);
  const B2CSettings: b2cSettingsText = (() => {
    try {
      return JSON.parse(b2cSettingsText || "{\"HotelTab\":\"None\",\"AirPortSearchType\":\"Most\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowDateInput\":\"Right\",\"ShowDefaultFilter\":\"On\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":false,\"preSelect\":false}},\"TermsConditionsLink\":\"https://dev.flight.agentplus.io\",\"ShowBackgroundModalColor\":\"Off\",\"showMarqueeText\":\"The flight will be delayed for 4 hours suraj\",\"showMarqueeFrom\":\"2024-05-31T10:55\",\"showMarqueeTo\":\"2024-05-30T10:58\",\"showBrandName\":\"On\",\"showAssistance\":\"On\",\"ConfirmationText\":\"<p>Thank you for booking with Everest Travel.\\n#Booking Reference#\\nYou will receive a confirmation email soon.</p>\",\"priceOption\":\"pricePerPerson\",\"Style\":\"body{\\ncolor:\\\"black\\\"\\n}\\n\"}");
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();
  let displayHeader = otherSettings.split("\n");
  var DisplayHeaderSection = displayHeader[1];
  DisplayHeaderSection = DisplayHeaderSection == "" ? "showFareHeader=On" : DisplayHeaderSection;
  const { defaultFilterSettings, validUntil } = props.data;
  const [fares, setfares] = useState(props?.data?.fares);
  const sessionInfoB2c = useSelector((state: State) => state.Session);
  const showBackModalColor = JSON.parse(
    sessionInfoB2c?.FrontendSettings?.b2cSettingsText
  );
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const InitialFilter = BuildFaresFilter(fares, defaultFilterSettings);
  const [filterState, setFilterState] = useState<FaresFilterState>(InitialFilter);
  const FilteredFares: AvailableFaresFare[] = useMemo(
    () => ApplyFilterToFares(fares, filterState),
    [fares, filterState]
  );

  const ApplicableFares = useMemo(
    () => FilteredFares.filter((x) => x.Applicability.Applicable === true),
    [FilteredFares]
  );

  //media query
  const matchesMediaQuery1 = useMediaQuery("(max-width:1024px)");
  const ApplicableFaresAmount = ApplicableFares.length;
  const [ShowAllLegsActive, setShowAllLegsActive] = useState(false);
  const [CheapestFareA, setCheapestFareA] = useState<any>();
  const [CheapestFareBool, setCheapestFareBool] = useState(false);
  const [SalesFareBool, setSalesFareBool] = useState(false);
  const [FastestFareBool, setFastestFareBool] = useState<boolean>(false);
  const [BestFareBool, setBestFareBool] = useState(false);
  const [flightDataArray, setFlightDataArray] = useState<FlightData[]>([]);
  const [filterName, setFilterName] = useState("");
  const [ShowAllFlightTimesActive, setShowAllFlightTimesActive] = useState(false);
  const [ShowAllCalculationActive, setShowAllCalculationActive] = useState(false);
  const TotalFaresAmount = fares.length;
  const myRefs = useRef<any>([]);
  const { i18n } = useTranslation();
  //checkedCarouselStateHolder
  const [selectedCarouselState, setSelectedCarouselState] = useState<any>([]);

  function ToggleFilter() {
    setFilterOpen(!filterOpen);
  }

  useEffect(() => {
    BuildFaresFilter(fares, defaultFilterSettings);
    setFilterOpen(defaultFilterSettings.defaultOpenBar);
  }, [defaultFilterSettings]);

  useEffect(() => {
    AutoFocusPage();
  }, [props.IsActive]);

  useEffect(() => {
    arrCollector = [];
    cssActiveChecker = [];
    repeatChecker = [];
  }, []);

  //extract pure Label function
  function extractSubstring(inputString: string) {
    let pattern = /^([^\(]+)/; // This pattern matches everything before the opening parenthesis
    let match = inputString.match(pattern);
    let extractedString = match ? match[0].trim() : ""; // Extracted string without leading or trailing spaces
    return extractedString;
  }

  useEffect(() => {
    sendPageHeight1();
    sendPageHeight();
  }, [ApplicableFares.length]);

  const sendPageHeight = () => {
    setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage(["setHeight", height + "px"], "*");
    }, 500);
  };

  const sendPageHeight1 = () => {
    const height = 1000;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  useEffect(() => {
    if (filterName == "cheapest") {
      // setCheapestFareA([]);
      CheapestFareCal();

      //carouselReActivate
      let updatedFilter = { ...filterState };
      // eslint-disable-next-line array-callback-return
      updatedFilter?.Airlines.map((obj: any, i: number) => {
        // eslint-disable-next-line array-callback-return
        arrCollector.map((itemCheck: any, j: number) => {
          const isPresent = _.some(arrCollector, {
            Value: obj?.Value,
          });
          if (isPresent) {
            obj.Checked = true;
          }
          if (!isPresent) {
            obj.Checked = false;
          }
        });
      });
      UpdateFilter(updatedFilter);
    }
    if (filterName == "best") {
      // setCheapestFareA([]);
      BestFareCal();
      //carouselReActivate
      let updatedFilter = { ...filterState };
      // eslint-disable-next-line array-callback-return
      updatedFilter?.Airlines.map((obj: any, i: number) => {
        // eslint-disable-next-line array-callback-return
        arrCollector.map((itemCheck: any, j: number) => {
          const isPresent = _.some(arrCollector, {
            Value: obj?.Value,
          });
          if (isPresent) {
            obj.Checked = true;
          }
          if (!isPresent) {
            obj.Checked = false;
          }
        });
      });
      UpdateFilter(updatedFilter);
    }
    if (filterName == "fastest") {
      // setCheapestFareA([]);
      FastestFareCal();
      //carouselReActivate
      let updatedFilter = { ...filterState };
      // eslint-disable-next-line array-callback-return
      updatedFilter?.Airlines.map((obj: any, i: number) => {
        // eslint-disable-next-line array-callback-return
        arrCollector.map((itemCheck: any, j: number) => {
          const isPresent = _.some(arrCollector, {
            Value: obj?.Value,
          });
          if (isPresent) {
            obj.Checked = true;
          }
          if (!isPresent) {
            obj.Checked = false;
          }
        });
      });
      UpdateFilter(updatedFilter);
    } 

  }, [filterName.length]);

  useEffect(() => {
    if (SalesFareBool) {
      setCheapestFareA([]);
      salesSort();
    }
  }, [SalesFareBool]);

  useEffect(() => {
    salesSort();
  }, []);

  const AddRepeteValu1 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f2");
    repeatChecker = repeatChecker.filter((item) => item !== "f3");
    repeatChecker = repeatChecker.filter((item) => item !== "f4");
    repeatChecker.push("f1");
    setCheapestFareBool(true);
    setFastestFareBool(false);
    setBestFareBool(false);
  };

  const AddRepeteValu2 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f1");
    repeatChecker = repeatChecker.filter((item) => item !== "f3");
    repeatChecker = repeatChecker.filter((item) => item !== "f4");
    repeatChecker.push("f2");
    setCheapestFareBool(false);
    setFastestFareBool(false);
    setBestFareBool(true);
  };

  const AddRepeteValu3 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f1");
    repeatChecker = repeatChecker.filter((item) => item !== "f2");
    repeatChecker = repeatChecker.filter((item) => item !== "f4");
    repeatChecker.push("f3");
    setCheapestFareBool(false);
    setFastestFareBool(true);
    setBestFareBool(false);
  };

  const AddRepeteValu4 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f1");
    repeatChecker = repeatChecker.filter((item) => item !== "f2");
    repeatChecker = repeatChecker.filter((item) => item !== "f3");
    repeatChecker.push("f4");
    setCheapestFareBool(false);
    setFastestFareBool(true);
    setBestFareBool(false);
  };

  const RemoveAllValu = () => {
    repeatChecker = [];
    setCheapestFareA([]);
    salesSort();
  };

  var tempArr = [];

  const calculateUniqueTotalTravelStopsPerLeg = (): number[] => {
    const uniqueTravelStops: Set<number> = new Set();

    // Iterate over each fare
    fares.forEach((fare: AvailableFareModel) => {
      // Initialize total stops for all legs in this fare
      let totalStopsForFare: number = 0;

      // Iterate over each leg within the fare
      fare.legs.forEach((leg: AvailableFareLegModel) => {
        // Ensure there are connections in the leg
        if (leg.connections.length > 0) {
          const firstConnection = leg.connections[0]; // Get the first connection in the leg
          // Check if the first connection has travel stops
          if (firstConnection.connectionHeader?.travelStopps !== undefined) {
            // Add the travel stops of the first connection to the total stops for this fare
            totalStopsForFare += firstConnection.connectionHeader.travelStopps;
          }
        }
      });

      // Add the total stops for this fare to the set
      uniqueTravelStops.add(totalStopsForFare);
      tempArr.push(fare);
    });

    // Convert the Set to an array and sort it
    const stops = Array.from(uniqueTravelStops).sort((a, b) => a - b);
    return stops;
  };

  const getStopsData = (stops: number): StopsData | undefined => {
    const stopsData: number[] = calculateUniqueTotalTravelStopsPerLeg();
    const faresByStops: { [key: number]: AvailableFareModel[] } = {};
    // Iterate through the stops data
    stopsData.forEach((stops: number) => {
      // Filter fares based on the current travel stop value
      const filteredFares = fares.filter((fare: AvailableFareModel) => {
        // Initialize total stops for all legs in this fare
        let totalStopsForFare: number = 0;

        // Iterate over each leg within the fare
        fare.legs.forEach((leg: AvailableFareLegModel) => {
          // Ensure there are connections in the leg
          if (leg.connections.length > 0) {
            const firstConnection = leg.connections[0]; // Get the first connection in the leg
            // Check if the first connection has travel stops
            if (firstConnection.connectionHeader?.travelStopps !== undefined) {
              // Add the travel stops of the first connection to the total stops for this fare
              totalStopsForFare +=
                firstConnection.connectionHeader.travelStopps;
            }
          }
        });

        // Return true if the total stops for this fare match the current travel stop value
        return totalStopsForFare === stops;
      });

      // Store the filtered fares in the object with stops as the key
      faresByStops[stops] = filteredFares;
    });

    const totalTravelTime = faresByStops[stops][0].legs.reduce(
      (totalMinutes, leg) => {
        // Access the leg's connection and get the travel time
        const legTravelTime =
          leg.connections[0]?.connectionHeader?.legTravelTime;

        // Add the leg's travel time to the total in minutes
        if (legTravelTime) {
          totalMinutes += legTravelTime.addHours * 60 + legTravelTime.minutes; // Convert hours to minutes
        }

        return totalMinutes;
      },
      0
    );

    // Convert total minutes to hours and minutes
    const totalHours = Math.floor(totalTravelTime / 60);
    const remainingMinutes = totalTravelTime % 60;

    // Create an object with total hours and remaining minutes
    const travelTimeObject = {
      hours: totalHours,
      minutes: remainingMinutes,
    };

    return {
      minPrice: faresByStops[stops][0].totalPrice,
      totalTravelTime: travelTimeObject,
    };
  };



  // Cheapest Sort for Header
  const salesSort = () => {
    const airlines: { Value: string; Label: string; Checked: boolean }[] = [];
    const alliances: { Value: string; Label: string; Checked: boolean }[] = [];

    // Sorting fares based on salesPreferencePriority and totalPrice
    setfares(
      fares.slice().sort((a: any, b: any) => {
        const priorityComparison =
          (b?.salesPreferencePriority ?? 0) - (a?.salesPreferencePriority ?? 0);
        return priorityComparison !== 0 ? priorityComparison : a.totalPrice - b.totalPrice;
      })
    );

    setSalesFareBool(true);

    // Grouping fares by carrierCode and selecting the one with minimum salesPreferencePriority
    const sortedFares = _(flightDataArray)
      .groupBy("carrierCode")
      .mapValues((fares) => _.minBy(fares, "salesPreferencePriority"))
      .values()
      .value();

    // Extracting values from InitialFilter.Airlines (excluding "K2")
    const values = _.chain(InitialFilter?.Airlines)
      .map("Value")
      .filter((value) => value !== "K2")
      .value();

    // Filter sortedFares and handle platingCarrier conditions for airlines and alliances
    const carouselSalesPreference = _.filter(sortedFares, (item) => {
      if (!item) return false; // Check if item is defined
      const includeItem =
        _.includes(values, item?.carrierCode) || _.includes(values, item?.platingCarrierCode);
      // Instead of accessing legs, directly check platingCarrier conditions
      if (includeItem) {
        if (item.platingCarrierCode && item.platingCarrierCode !== "") {
          if (!airlines.some((x) => x.Value === item.platingCarrierCode)) {
            airlines.push({
              Value: item.platingCarrierCode,
              Label: `${item.hint} (${item.platingCarrierCode})`, // Assuming hint refers to the plating carrier
              Checked: true,
            });
          }
        }

        // If you have an alliance code, process it similarly
        // This part needs to be updated as per your actual data structure
        // Assuming you want to handle alliances here:
        if (item.platingCarrierCode && item.platingCarrierCode !== "") {
          // Example placeholder, you should update this based on your actual logic to fetch alliances
          const allianceCode = item.platingCarrierCode; // Update this with actual alliance code if available

          if (!alliances.some((x) => x.Value === allianceCode)) {
            alliances.push({
              Value: allianceCode,
              Label: `Alliance Name`, // Replace with actual alliance name if available
              Checked: true,
            });
          }
        }
      }
      return includeItem;
    });
    setCheapestFareA(carouselSalesPreference);
  };

  function CheapestFareCal() {

    if (props.fareSortStores?.cheapestFare!.length == 0) {
      _.sortBy(fares, ["totalPrice"]);
      fares.sort((a, b) => (a.totalPrice > b.totalPrice ? 1 : -1));
      const CheapFares = fares;

      CheapFares.forEach(function (fare) {
        if (fare.legs[0].platingCarrier?.code) {
          fare.legs.map((leg) => {
            let timeFareLoop: any = [];
            leg.connections.map((con) => {
              return timeFareLoop.push(
                getTotalMinutes(con.connectionHeader?.legTravelTime)
              );
            });
          });
        }
      });

      // const sortedFares = _.uniqBy(_.sortBy(flightDataArray, "totalPrice"), "carrierCode");
      const sortedFares = _.flatten(
        _.map(_.groupBy(flightDataArray, "carrierCode"), (group) =>
          _.minBy(group, "totalPrice")
        )
      );

      // Extracting the values from obj1
      const values = _.map(InitialFilter?.Airlines, "Value");

      // Filtering obj2 based on the condition
      const cheapestCarousel = _.filter(sortedFares, (item) =>
        _.includes(values, item?.carrierCode)
      );

      setCheapestFareA(cheapestCarousel);
      props.setFareSortStores((prevState: any) => ({
        ...prevState, // Spread the previous state to preserve other properties
        cheapestCarousel: [...cheapestCarousel], // Replace `BestFare` with the new value
        cheapestFare: [...fares]
      }));
      // setFilterName("cheapest");
    }
    if (props.fareSortStores?.cheapestFare!.length != 0 && props.fareSortStores?.cheapestCarousel!.length != 0) {
      setfares(props.fareSortStores?.cheapestFare);
      setCheapestFareA(props.fareSortStores?.cheapestCarousel);
      // setFilterName("cheapest");
    }
  }
  //cal best order for carousel
  function calculateCombinedScore(fare: any) {
    const totalHr = fare.time.hours + fare.time.minutes / 60;
    const normalizedPrice = fare.totalPrice / 1000; // Normalize price (adjust as needed)
    const normalizedTravelTime = totalHr / 10; // Normalize travel time (adjust as needed)
    const weightPrice = 0.6; // Weight for price
    const weightTravelTime = 0.4; // Weight for travel time
    return (weightPrice * normalizedPrice) + (weightTravelTime * normalizedTravelTime);
  }

  function sortFaresByCombinedScore(fares: any) {
    const faresWithScores = _.map(fares, fare => {
      return {
        ...fare,
        combinedScore: calculateCombinedScore(fare)
      };
    });

    const sortedFares = _.sortBy(faresWithScores, 'combinedScore');
    return sortedFares;
  }

  //Best Sort for Header
  function BestFareCal() {
    if (props.fareSortStores?.BestFare?.length == 0) {
      const bestFlightsCarousel = sortFaresByCombinedScore(flightDataArray);
      const uniqueBestFlightsCarousel = _.uniqBy(bestFlightsCarousel, "carrierCode");
      // Extracting the values from obj1
      const values = _.map(InitialFilter?.Airlines, "Value");
      const bestFare = sortFaresByCombinedScoreFares(fares);

      // Filtering obj2 based on the condition
      const bestCarousel = _.filter(uniqueBestFlightsCarousel, (item) =>
        _.includes(values, item?.carrierCode)
      );

      setfares(bestFare)
      setCheapestFareA(bestCarousel);
      // setFilterName("best");
      const bestFareSorted: any = bestFare;
      props.setFareSortStores((prevState: any) => ({
        ...prevState, // Spread the previous state to preserve other properties
        BestCarousel: [...bestCarousel], // Replace `BestFare` with the new value
        BestFare: [...bestFareSorted]
      }));
    }
    if (props.fareSortStores?.BestFare!.length != 0 && props.fareSortStores?.BestCarousel!.length != 0) {
      setfares(props.fareSortStores?.BestFare);
      setCheapestFareA(props.fareSortStores?.BestCarousel);
      // setFilterName("best");
    }
  }

  //  Fastest Sort For Header
  // Function to calculate the total travel time in minutes
  function getTotalTravelTimeForFastest(time: any) {
    let totalMinutes = (time.daysStayOver * 24 * 60) + (time.addHours * 60) + (time.hours * 60) + time.minutes;
    return totalMinutes;
  }

  function sortFaresByFastestTime(fares: any) {
    // Compute total travel time for each fare and add it as a new property
    const faresWithTravelTime = fares.map((fare: any) => ({
      ...fare,
      totalTravelTime: getTotalTravelTimeForFastest(fare.time)
    }));

    // Sort fares by total travel time in ascending order
    const sortedFares = _.sortBy(faresWithTravelTime, 'totalTravelTime');

    return sortedFares;
  }

  function FastestFareCal() {
    if (props.fareSortStores?.fastFare?.length == 0) {
      fares.sort((a, b) => {
        const aMinLegTravelTime = _.meanBy(
          _.flatMap(a.legs, (leg) =>
            _.minBy(
              _.map(leg.connections, (connection) =>
                getTotalMinutes(
                  _.get(connection, "connectionHeader.legTravelTime", 0)
                )
              )
            )
          )
        );
        const bMinLegTravelTime = _.meanBy(
          _.flatMap(b.legs, (leg) =>
            _.minBy(
              _.map(leg.connections, (connection) =>
                getTotalMinutes(
                  _.get(connection, "connectionHeader.legTravelTime", 0)
                )
              )
            )
          )
        );
        if (aMinLegTravelTime !== bMinLegTravelTime) {
          return aMinLegTravelTime - bMinLegTravelTime;
        } else {
          // Sort connections within each leg based on legTravelTime
          _.forEach(a.legs, (leg) => {
            leg.connections = _.sortBy(leg.connections, (connection) =>
              getTotalMinutes(
                _.get(connection, "connectionHeader.legTravelTime", 0)
              )
            );
          });

          _.forEach(b.legs, (leg) => {
            leg.connections = _.sortBy(leg.connections, (connection) =>
              getTotalMinutes(
                _.get(connection, "connectionHeader.legTravelTime", 0)
              )
            );
          });
          return 0;
        }
      });

      const fastestFlightsCarousel = sortFaresByFastestTime(flightDataArray);
      const uniqueFastestFlightsCarousel = _.uniqBy(fastestFlightsCarousel, "carrierCode");
      // Extracting the values from obj1
      const values = _.map(InitialFilter?.Airlines, "Value");
      // Filtering obj2 based on the condition
      const fastestCarousel = _.filter(uniqueFastestFlightsCarousel, (item) =>
        _.includes(values, item?.carrierCode)
      );
      setCheapestFareA(fastestCarousel);
      // setFilterName("fastest");
      const fastFareSorted: any = fares;
      props.setFareSortStores((prevState: any) => {
        return {
          ...prevState,
          fastCarousel: [...fastestCarousel], // Ensure new array
          fastFare: [...fastFareSorted], // Ensure new array
        };
      });
    }
    if (props.fareSortStores?.fastFare?.length != 0 && props.fareSortStores?.fastCarousel?.length != 0) {
      setfares(props.fareSortStores?.fastFare);
      setCheapestFareA(props.fareSortStores?.fastCarousel);
      // setFilterName("fastest");
    }
  }

  function GetTotalFlightTimeBest(legs: AvailableFareLegModel[]): TravelTimeView {
    return legs.reduce(
      (result, leg) => {
        const sortedConnections = leg.connections.sort((a, b) => {
          // Calculate total time for each connection by summing segment times
          const totalMinutesA = a.segments.reduce((sum, segment: any) => {
            const arrivalTime = new Date(segment.arrivalDate).getTime();
            const departureTime = new Date(segment.departureDate).getTime();
            return sum + (arrivalTime - departureTime) / (1000 * 60); // Convert to minutes
          }, 0);

          const totalMinutesB = b.segments.reduce((sum, segment: any) => {
            const arrivalTime = new Date(segment.arrivalDate).getTime();
            const departureTime = new Date(segment.departureDate).getTime();
            return sum + (arrivalTime - departureTime) / (1000 * 60); // Convert to minutes
          }, 0);

          return totalMinutesA - totalMinutesB;
        });

        // Get the shortest connection time for this leg
        const shortestConnectionTime = sortedConnections[0]?.segments.reduce((sum, segment: any) => {
          const arrivalTime = new Date(segment.arrivalDate).getTime();
          const departureTime = new Date(segment.departureDate).getTime();
          return sum + (arrivalTime - departureTime) / (1000 * 60); // Convert to minutes
        }, 0);

        if (shortestConnectionTime && shortestConnectionTime > 0) {
          result.minutes += shortestConnectionTime;
          result.hours += Math.floor(result.minutes / 60);
          result.minutes = result.minutes % 60;
        }

        return result;
      },
      { minutes: 0, hours: 0, addHours: 0, daysStayOver: 0 }
    );
  }

  function ShortFareCal() {
    // sort fare by duration and price
    fares.sort((a, b) => {
      return ((bestFareSorter(a)) / fares.length) * 100 >
        ((bestFareSorter(b)) / fares.length) * 100
        ? 1
        : -1
    }
    );

    function bestFareSorter(singleFare: any): number {
      const time = GetTotalFlightTimeBest(singleFare.legs);
      const totalMinutes = time.hours * 60 + time.minutes;
      return totalMinutes;
    }

    const bestFlights = _.sortBy(flightDataArray, (flight) => {
      const totalMinutes = flight.time.addHours * 60 + flight.time.minutes;
      return (
        ((flight.totalPrice + totalMinutes) / flightDataArray.length) * 100
      );
    });
    const uniqueBestFlights = _.uniqBy(bestFlights, "carrierCode");
    setCheapestFareA(uniqueBestFlights);
  }

  function GetTotalFlightTime(legs: AvailableFareLegModel[]): TravelTimeView {
    return legs.reduce(
      (result, leg) => {
        const sortedConnections = leg.connections.sort((a, b) => {
          const legTravelTimeA = a.connectionHeader?.legTravelTime || {
            addHours: Infinity,
            minutes: Infinity,
          };
          const legTravelTimeB = b.connectionHeader?.legTravelTime || {
            addHours: Infinity,
            minutes: Infinity,
          };
          const totalMinutesA =
            legTravelTimeA.addHours * 60 + legTravelTimeA.minutes;
          const totalMinutesB =
            legTravelTimeB.addHours * 60 + legTravelTimeB.minutes;
          return totalMinutesA - totalMinutesB;
        });

        const legTime = sortedConnections[0]?.connectionHeader?.legTravelTime;
        if (legTime) {
          result.minutes += legTime.minutes;
          result.hours += legTime.addHours;
        }
        // Convert excess minutes into hours
        if (result.minutes >= 60) {
          result.hours += Math.floor(result.minutes / 60);
          result.minutes = result.minutes % 60;
        }
        return result;
      },
      { minutes: 0, hours: 0, addHours: 0, daysStayOver: 0 }
    );
  }

  //custom getTotalFlightTime
  function getTotalFlightTime(legs: any) {
    const totalTime = _.reduce(legs, (result, leg) => {
      const sortedConnections = _.sortBy(leg.connections, connection => {
        const { addHours, minutes, daysStayOver } = connection.connectionHeader.legTravelTime || { addHours: Infinity, minutes: Infinity, daysStayOver: 0 };
        return (addHours * 60) + minutes;
      });

      const legTime = sortedConnections[0]?.connectionHeader?.legTravelTime;
      if (legTime) {
        result.minutes += legTime.minutes;
        result.hours += legTime.addHours;
      }

      return result;
    }, { minutes: 0, hours: 0 });

    return totalTime.hours + totalTime.minutes / 60;
  }

  function calculateCombinedScoreFares(fare: any) {
    const totalTravelTime = getTotalFlightTime(fare.legs);
    const normalizedPrice = fare.totalPrice / 1000; // Normalize price (adjust as needed)
    const normalizedTravelTime = totalTravelTime / 10; // Normalize travel time (adjust as needed)
    const weightPrice = 0.6; // Weight for price
    const weightTravelTime = 0.4; // Weight for travel time
    return (weightPrice * normalizedPrice) + (weightTravelTime * normalizedTravelTime);
  }

  function sortFaresByCombinedScoreFares(fares: any) {
    const faresWithScores = _.map(fares, fare => {
      return {
        ...fare,
        combinedScore: calculateCombinedScoreFares(fare)
      };
    });

    const sortedFares = _.sortBy(faresWithScores, 'combinedScore');
    return sortedFares;
  }

  useEffect(() => {
    LoadDefaultSlider();
  }, [fares]);

  const LoadDefaultSlider = () => {
    const updatedFlightDataMap: { [fareIndex: number]: FlightData } = {};
    fares.forEach((fare) => {
      const totalTime: TravelTimeView = GetTotalFlightTime(fare.legs);
      fare.legs.forEach((leg) => {
        // Extract required data once and structure it clearly
        const { code: platingCarrierCode, hint } = leg.platingCarrier || {};
        const { currency, totalPrice, fareInfoBox } = fare;
        // Construct flight data object
        const flightData: FlightData = {
          platingCarrierCode,
          carrierCode: leg.carrierCode,
          mileage: leg.mileage,
          currency,
          fareIndex: leg.fareIndex,
          hint,
          totalPrice,
          salesPreferencePriority: fareInfoBox.salesPreferencePriority,
          time: totalTime,
        };
        // Only add to map if carrier code is not 'K2'
        if (flightData.carrierCode !== 'K2') {
          updatedFlightDataMap[leg.fareIndex] = flightData;
        }
      });
    });
    // Convert flight data map to an array
    const updatedFlightDataArray = Object.values(updatedFlightDataMap);
    // Update the state with the flight data array
    setFlightDataArray(updatedFlightDataArray);

    // Return updated flight data array if needed
    return updatedFlightDataArray;
  };

  function getTotalMinutes(legTravelTime: any): any {
    const { addHours, minutes } = legTravelTime;
    return addHours * 60 + minutes;
  }

  // Toggle All Legs
  function ToggleAllLegs() {
    setShowAllLegsActive(!ShowAllLegsActive);
  }

  // Toggle All Flight Times
  function ToggleAllFlightTimes() {
    setShowAllFlightTimesActive(!ShowAllFlightTimesActive);
  }
  // Toggle All Calculation
  function ToggleAllCalculation() {
    setShowAllCalculationActive(!ShowAllCalculationActive);
  }

  // update Fileter
  function UpdateFilter(filter: FaresFilterState) {
    setFilterState(filter);
  }
  // Apply Filter To Fares
  function ApplyFilterToFares(
    fares: AvailableFareModel[],
    filter: FaresFilterState | undefined
  ): AvailableFaresFare[] {
    const res = fares.map((fare: AvailableFareModel) => {
      let result: AvailableFaresFare = {
        Fare: fare,
        Applicability: FareAppliesToFilter(fare, filter!),
      };

      if (result.Applicability.ApplicableConnections) {
        if (
          result.Applicability.ApplicableConnections.length !== fare.legs.length
        ) {
          let a = 0;
        }
      }
      return result;
    });
    return res;
  }

  // Auto Focus
  function AutoFocusPage() {
    const x = document.getElementById("faresdisplay");
    if (x) {
      x.focus({ preventScroll: true });
    }
  }
  const EnableCalcDebugger = useSelector(
    (state: State) => state.Session.FrontendSettings.enableCalculationDeugger
  );
  //carousel Cheapest flight
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobileUpper: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // carousel functionality when unselected comes all airlines
  useEffect(() => {
    if (arrCollector?.length == 0) {
      DeactivateAllAlliances(true, true);
      cssActiveChecker = [];
    }
    if (arrCollector?.length != 0) {
      setSelectedCarouselState(arrCollector);
    }
    sendPageHeight();
  }, [arrCollector?.length]);

  //cssActiveChecker clean it func()
  const cssActiveCheckerClean = () => {
    cssActiveChecker = [];
  };

  //toggle all airlines
  function DeactivateAllAlliances(
    BolCheck: boolean,
    isOutsideTriggerCheck: boolean = false
  ) {
    // Assuming filterState and arrCollector are defined elsewhere
    let updatedFilter = { ...filterState };

    // Update Checked property of each airline
    updatedFilter.Airlines.forEach((element) => {
      element.Checked = BolCheck;
    });

    // Update SelectAllAirlinesActive based on Checked values
    updatedFilter.SelectAllAirlinesActive =
      updatedFilter.Airlines.every((el) => el.Checked) &&
      updatedFilter.Airlines.length > 0;

    // Reset cssActiveChecker and arrCollector if isOutsideTriggerCheck is true
    // if (isOutsideTriggerCheck) {
    //     cssActiveChecker = [];
    //     arrCollector = [];
    // }

    // Call UpdateFilter function with updated filter
    UpdateFilter(updatedFilter);
  }

  function getCheckedStatusByLabel(
    label: string,
    isDuplicateAirlineLabel: boolean
  ) {
    for (var i = 0; i < cssActiveChecker.length; i++) {
      if (
        !isDuplicateAirlineLabel
          ? cssActiveChecker[i].Label == label
          : extractSubstring(cssActiveChecker[i].Label) ==
          extractSubstring(label)
      ) {
        return cssActiveChecker[i].Checked;
      }
    }
    return false; // Return null if label not found
  }

  const TotalPassengers = props?.data?.fares[0]?.bookingPassengers.length
  function Item(props: any) {
    const [error, setError] = useState(false);
    let checkDuplicateAirways: any = false;
    //Toggle a particular selected airlines
    function TogglePAirline(label: string, airlineCode: string) {
      DeactivateAllAlliances(false, false);
      let updatedFilter = { ...filterState };
      let newString = `${label} (${airlineCode})`;
      const britishAirwaysCount = _.filter(
        updatedFilter?.Airlines,
        ({ Label }) => Label.includes(label)
      ).length;

      const isDuplicateAirlineLabel = britishAirwaysCount > 1;
      checkDuplicateAirways = isDuplicateAirlineLabel;

      let arrayCreated: any = [];
      cssActiveChecker = [];
      // eslint-disable-next-line array-callback-return
      arrayCreated = updatedFilter?.Airlines?.filter((el: any) => {
        if (
          el.Value == airlineCode ||
          (isDuplicateAirlineLabel
            ? false
            : extractSubstring(el.Label) == extractSubstring(newString))
        ) {
          return el;
        }
      });
      if (arrCollector.includes(...arrayCreated)) {
        arrCollector = arrCollector.filter((el: any) => el != arrayCreated[0]);
      } else {
        arrCollector.push(...arrayCreated);
      }
      // eslint-disable-next-line array-callback-return
      updatedFilter?.Airlines.map((obj: any, i: number) => {
        // eslint-disable-next-line array-callback-return
        arrCollector.map((itemCheck: any, j: number) => {
          if (obj.Value == itemCheck.Value) {
            obj.Checked = true;
          }
        });
      });

      UpdateFilter(updatedFilter);
      if (CheapestFareA?.length || CheapestFareA != undefined)
        CheapestFareA.map((data: any, i: number) => {
          return updatedFilter?.Airlines?.filter((el: any): any => {
            if (el.Value == data.carrierCode) {
              cssActiveChecker.push(el);
              return el;
            }
          });
        });
    }

    return (
      <Paper
        square
        className={
          getCheckedStatusByLabel(
            `${props.item.hint} (${props.item.carrierCode})`,
            checkDuplicateAirways
          ) && arrCollector.length != 0
            ? "activeTab"
            : ""
        }
      >
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            padding: "5px",
          }}
          onClick={() => {
            TogglePAirline(props.item.hint, props.item.carrierCode);
          }}
        >
          <img
            width="120"
            height="40"
            src={`${error
              ? Logo
              : `${AgentApiURL}/publicservices/images/airline/${props.item.carrierCode}`
              }`}
            alt="plating"
            onError={() => setError(true)}
          />
          <div style={{ marginLeft: "8px" }}>
            <div className="textEllipseFare">{props.item.hint}</div>
            <div>
              {LocalizationConverter(
                parseFloat(B2CSettings.priceOption == "pricePerPerson" ? (props.item.totalPrice / TotalPassengers) : props.item.totalPrice).toFixed(2),
                i18n.language
              )}
              <span>{currencyFormatter(props.item.currency)} </span>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
  //filter sidebar state
  const sessionInfo = useSelector((state: State) => state.Session);

  const Filter: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off","showFilterPosition": "Right"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
        showFilterPosition: "Right"
      };
    }
  })();

  const [sidebarIsOpen, setSidebarOpen] = useState(
    Filter?.ShowDefaultFilter == "On" ? false : true
  );
  //scroll-upper btn
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsVisible(scrollTop > 200); // Adjust the value based on when you want the button to appear
  };

  // Add the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // form hide open when scroll
  const [scrollingDown, setScrollingDown] = useState(true);

  useEffect(() => {
    const handleScrollHide = () => {
      const scrollTop = window.scrollY;
      // Check if scrolling down
      if (scrollTop > 100 && !scrollingDown) {
        setScrollingDown(true);
      }
    };
    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScrollHide);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScrollHide);
    };
  }, [scrollingDown]);

  //Filter modal
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  function makingCarouselValueEmpty() {
    arrCollector = [];
    cssActiveChecker = [];
  }

  return (
    <div
      id={"faresdisplay"}
      tabIndex={0}
      className={scrollingDown ? "abcd bcFare" : " bcFare"}>
      <div
        className={scrollingDown ? "fareHeader heightHeaderScroll" : "fareHeader"}
      >
        <FareHeaderList
          OnReset={props.OnReset}
          IsModifySearch={props.IsModifySearch}
          OnSearchSubmitted={props.OnSearchSubmitted}
          LastSubmitted={props.LastSubmitted}
          submitResult={props.submitResult}
          GoToResults={props.GoToResults}
          Config={undefined}
          Init={undefined}
          showSearchForm={true}
          OnModify={props.OnModify}
          boxRef={props.setHeightHolder}
          activeTabHF={props.activeTabHF}
          setActiveTabHF={props.setActiveTabHF}
          scrollingDown={scrollingDown}
          setScrollingDown={setScrollingDown}
          language={props.language}
        />
      </div>
      <button
        style={{
          position: "fixed",
          right: "88px",
          zIndex: "1001",
          top: "18px",
        }}
        className="btn btn-light  my-2 mx-1 text-end"
        onClick={() => { matchesMediaQuery1 ? toggleModal() : setSidebarOpen(!sidebarIsOpen); }}
      >
        <FontAwesomeIcon icon={faFilter} />
      </button>
      <div
        className={Filter?.showFilterPosition == "Right" ? "App wrapper" : "App wrapper flexDirectionRowReverse"}
      >
        <div
          className={classNames("content ml-2", {
            "is-open": sidebarIsOpen,
          })}
          style={{ marginTop: "5px" }}
        >
          {TotalFaresAmount > 0 && <FastFilter />}
          {DisplayHeaderSection == "showFareHeader=On" && (
            <div>
              <SortFunction
                cssActiveChecker={cssActiveChecker!}
                DeactivateAllAlliances={DeactivateAllAlliances!}
                fares={fares}
                cheapestFare={CheapestFareCal}
                fastestFare={FastestFareCal}
                bestFare={BestFareCal}
                shortFare={ShortFareCal}
                repeatChecker={repeatChecker}
                AddRepeteValu1={AddRepeteValu1}
                AddRepeteValu2={AddRepeteValu2}
                AddRepeteValu3={AddRepeteValu3}
                AddRepeteValu4={AddRepeteValu4}
                removeAllValu={RemoveAllValu}
                setFilterName={setFilterName}
              />
              <div style={{ margin: "3px" }} className="bg-white">
                <Carousel responsive={responsive}>
                  {CheapestFareA != undefined
                    ? CheapestFareA.map((item: any, i: any) => (
                      <Item
                        className="activeTab"
                        key={"cheap" + i}
                        item={item}
                        indexGetter={i}
                      />
                    ))
                    : []}
                </Carousel>
              </div>
            </div>
          )}
          <div style={{ marginTop: "6px", marginBottom: "50px" }}>
            <Fares
              airlineCollector={arrCollector}
              Fares={ApplicableFares}
              TotalFaresAmount={TotalFaresAmount}
              ShowAllCalculationActive={ShowAllCalculationActive}
              ShowAllFlightTimesActive={ShowAllFlightTimesActive}
              ShowAllLegsActive={ShowAllLegsActive}
              FaresWhichPassfilter={ApplicableFaresAmount}
              ValidUntil={validUntil}
              BookFare={props.BookFare}
              ShowCalcDebugger={EnableCalcDebugger}
              myRefs={myRefs!}
              setFetching={props.setFetching}
              setError={props.setError}
              setResponse={props.setResponse}
              setExtraAncCheck={props.setExtraAncCheck}
            />
          </div>
        </div>

        <div>
          {matchesMediaQuery1 ? (
            <div className="w-75">
              <Modal isOpen={modalOpen} toggle={toggleModal} className="w-75" backdropClassName={showBackModalColor?.ShowBackgroundModalColor == "On" ? "loaderColorHalf" : "loaderColorZero"}>
                <ModalHeader toggle={toggleModal}>Filter </ModalHeader>
                <ModalBody>
                  <div className="popup-inner">
                    <div>
                      <FaresFilter
                        TotalFaresAmount={TotalFaresAmount}
                        FilteredFaresAmount={ApplicableFaresAmount}
                        Filter={filterState}
                        UpdateFilter={UpdateFilter}
                        InitialFilter={InitialFilter}
                        IsOpen={filterOpen}
                        Toggle={ToggleFilter}
                        AllCalculationExpandActive={ShowAllCalculationActive}
                        AllCalculationExpandedToggle={ToggleAllCalculation}
                        AllFaresExpandActive={ShowAllLegsActive}
                        AllFaresExpandedToggle={ToggleAllLegs}
                        AllFlightTimesExpandActive={ShowAllFlightTimesActive}
                        AllFlightTimesExpandedToggle={ToggleAllFlightTimes}
                        cssActiveCheckerClean={cssActiveCheckerClean!}
                        getStops={getStopsData}
                        travelStops={calculateUniqueTotalTravelStopsPerLeg}
                        makingCarouselValueEmpty={makingCarouselValueEmpty}
                        totalPassenger={TotalPassengers}
                      />
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          ) : (
            <div
              className={classNames(`${Filter?.showFilterPosition == "Right" ? 'sidebar' : 'sidebar positionLeft'}`, { "is-open": sidebarIsOpen })}
            >

              <FaresFilter
                TotalFaresAmount={TotalFaresAmount}
                FilteredFaresAmount={ApplicableFaresAmount}
                Filter={filterState}
                UpdateFilter={UpdateFilter}
                InitialFilter={InitialFilter}
                IsOpen={filterOpen}
                Toggle={ToggleFilter}
                AllCalculationExpandActive={ShowAllCalculationActive}
                AllCalculationExpandedToggle={ToggleAllCalculation}
                AllFaresExpandActive={ShowAllLegsActive}
                AllFaresExpandedToggle={ToggleAllLegs}
                AllFlightTimesExpandActive={ShowAllFlightTimesActive}
                AllFlightTimesExpandedToggle={ToggleAllFlightTimes}
                cssActiveCheckerClean={cssActiveCheckerClean!}
                getStops={getStopsData}
                travelStops={calculateUniqueTotalTravelStopsPerLeg}
                makingCarouselValueEmpty={makingCarouselValueEmpty}
                totalPassenger={TotalPassengers}
              />
            </div>
          )}
        </div>
        <div
          className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}
          onClick={scrollToTop}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </div>
      </div>
    </div>
  );
};

